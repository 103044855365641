import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCredentials } from 'src/redux/slices/authSlice';
import { orgService } from 'src/services/orgService';

function useCheckProcessedStatus() {
    const { token, processedStatus } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (processedStatus) {
            navigate('/');  // Redirect to homepage if processedStatus is true
            return;  // Stop further execution to prevent unnecessary checks after redirect
        }

        const checkStatus = async () => {
            if (token) {  // Only proceed if a token is available
                try {
                    const orgData = await orgService.getOrganization(token);
                    if (orgData && orgData.length > 0) {
                        const awsAccountData = orgData[0]?.aws_accounts;
                        const newProcessedStatus = awsAccountData[0]?.processed_status;
                        console.log('New processed status after check:', newProcessedStatus);
                        console.log('Last checked at time:', new Date().toLocaleTimeString());

                        // Update Redux state and navigate if processed status is true
                        dispatch(setCredentials({ user: orgData.user, token: token, processedStatus: newProcessedStatus }));
                        if (newProcessedStatus) {
                            navigate('/');  // Redirect to the homepage
                        }
                    }
                } catch (error) {
                    console.error('Failed to fetch organization data:', error);
                }
            }
        };

        const interval = setInterval(checkStatus, 10000);  // Check status every 10 seconds

        return () => clearInterval(interval);
    }, [navigate, dispatch, token, processedStatus]);

    return null; // This hook does not return anything
}

export default useCheckProcessedStatus;
