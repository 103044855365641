import api from './api';

// Function to add an AWS account to the organization
const addAwsAccount = async (clientArn, token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };

    const response = await api.post('/aws/admin/account/', {
        client_arn: clientArn
    }, config);
    return response.data;
};

// Function to fetch the compute cost for the organization
const fetchComputeCost = async (accountId, token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.get(`/aws/admin/compute_cost`, config);
    return response.data;
}

// Function to fetch AWS instances for the organization
const fetchAwsInstances = async (accountId, token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.get(`/aws/instances/?account_id=${accountId}&cloud=True`, config);
    return response.data;
};

const fetchSavedInstances = async (accountId, token, filters) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    if (filters) {
        const filterString = Object.keys(filters).map(key => `${key}=${filters[key]}`).join('&');
        const response = await api.get(`/aws/instances/?account_id=${accountId}&${filterString}`, config);
        return response.data;
    }
    const response = await api.get(`/aws/instances/?account_id=${accountId}`, config);
    return response.data;
};

// Function to fetch the optimization history of a specific AWS instance
const fetchInstanceHistory = async (accountId, instanceId, token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.get(`/aws/instances/?account_id=${accountId}&instance_id=${instanceId}&history=true`, config);
    return response.data;
};

// Function to create a new AWSMO group with instance IDs and other details
const createAwsmoGroup = async (groupData, token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };

    const response = await api.post('/aws/instances/awsmo-groups/', groupData, config);
    return response.data;
};
// Function to update an existing AWSMO group with groupId for instance IDs and other details
const updateAwsmoGroup = async (groupId, groupData, token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };

    const response = await api.put(`/aws/instances/awsmo-groups/${groupId}/`, groupData, config);
    // return response status code
    return response.status;
};

const fetchAwsmoGroups = async (token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.get(`/aws/instances/awsmo-groups/`, config);
    return response.data;
};

const fetchMaintenanceWindows = async (token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.get(`/aws/instances/maintenance-windows/`, config);
    return response.data;
}

const createMaintenanceWindow = async (windowData, token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.post('/aws/instances/maintenance-windows/', windowData, config);
    return response.data;
};

const updateMaintenanceWindow = async (windowId, windowData, token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.put(`/aws/instances/maintenance-windows/${windowId}/`, windowData, config);
    return response.data;
}

const fetchAwsmoGroupDetails = async (groupId, token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.get(`/aws/instances/awsmo-groups/${groupId}/`, config);
    return response.data;
}

const fetchNotifications = async (token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.get(`/notifications/`, config);
    return response.data;
}

const deleteNotification = async (notificationId, token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.delete(`/notifications/${notificationId}/`, config);
    return response.data;
};

const fetchLoadBalancers = async (token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.get(`/aws/instances/load-balancers/`, config);
    return response.data;
}

const fetchLoadBalancerById = async (id, token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.get(`/aws/instances/load-balancers/${id}/`, config);
    return response.data;
}

const fetchAwsmoGroupHistory = async (groupId, token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.get(`/aws/instances/awsmo-group-history/?group_id=${groupId}`, config);
    console.log(response.data);
    return response.data;
}

const fetchAwsmoGroupCost = async (awsmoGroupId, token) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const response = await api.get(`/aws/instances/cost/?awsmo_group_id=${awsmoGroupId}`, config);
    return response.data;

}
const fetchInstanceUtilization = async (accountId, instanceIds, token, startTime, endTime, granularity) => {
    const config = {
        headers: { Authorization: `JWT ${token}` },
    };
    const payload = {
        start_time: startTime.toISOString(),
        end_time: endTime.toISOString(),
        instance_ids: instanceIds,
        granularity: granularity,
        aggregation_method: 'AVG'    // Hardcoded for now
    };
    const response = await api.post(`/ aws / instances / instance - consumption /? account_id = ${accountId}`, payload, config);
    return response.data;
};

export const awsService = {
    addAwsAccount,
    fetchComputeCost,
    fetchAwsInstances,
    fetchInstanceHistory,
    fetchSavedInstances,
    createAwsmoGroup,
    fetchAwsmoGroups,
    fetchAwsmoGroupDetails,
    updateAwsmoGroup,
    fetchMaintenanceWindows,
    createMaintenanceWindow,
    updateMaintenanceWindow,
    fetchNotifications,
    deleteNotification,
    fetchLoadBalancerById,
    fetchLoadBalancers,
    fetchAwsmoGroupCost,
    fetchInstanceUtilization,
    fetchAwsmoGroupHistory
};